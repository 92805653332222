import React from 'react';
import {Subheading} from 'app/styles/heading';
import styled, {css} from 'styled-components';
import {AddTeamPlayerTeaser} from 'app/components/team/addTeamPlayerTeaser';
import {AdSlot} from 'app/components/ads/AdSlot';
import ProfileCard from 'app/components/player/profileCard';
import {ProfileWrapper} from 'app/components/profile/ProfileStyles';

const Wrapper = styled.div`
  ${props =>
    props.nopadding &&
    css`
      h3 {
        padding-top: 0;
      }
    `}
`;

const playerRow = player => {
  return <ProfileCard key={player.slug} profile={player} role={'player'} />;
};

// https://learnwithparam.com/blog/how-to-group-by-array-of-objects-using-a-key/#:~:text=Steps%20to%20create%20the%20groupBy,value%20present%20in%20the%20currentValue
// Accepts the array and key
const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

const adUnitForPlayerPosition = {
  Angriff: <AdSlot key='MatchEvent_1' adUnit='MatchEvent_1' />,
  Mittelfeld: <AdSlot key='Rectangle_1' adUnit='Rectangle_1' />,
  Abwehr: <AdSlot key='Rectangle_2' adUnit='Rectangle_2' />,
  Torwart: null,
  unbekannt: null,
};

const PlayerList = ({hasTeamImage, squad, teamId}) => {
  const teamPlayers = groupBy(squad, 'position');

  const getPlayers = (position, index) => {
    const headingTitle = position !== 'unbekannt' ? position : 'Unbekannte Position';
    const heading = <Subheading variant='subheading2'>{headingTitle}</Subheading>;
    const rows = teamPlayers[position].map(player => playerRow(player));

    // Split array for first section into two arrays, first array inculdes 4 up to 6 elements, second array includes the rest
    const isFirstSection = index === 0;
    const end = hasTeamImage ? 4 : 6;
    const first4Players = isFirstSection ? rows.slice(0, end) : rows;
    const restPlayers = isFirstSection ? rows.slice(end) : null;

    const adUnit = adUnitForPlayerPosition[position];

    return (
      <Wrapper nopadding={position === 'Torwart'} key={position}>
        {heading}
        <ProfileWrapper>{first4Players}</ProfileWrapper>
        {adUnit}
        {restPlayers && <ProfileWrapper>{restPlayers}</ProfileWrapper>}
      </Wrapper>
    );
  };

  const getPlaceholder = position => <AddTeamPlayerTeaser key={position} position={position} teamId={teamId} />;

  const positions = ['Angriff', 'Mittelfeld', 'Abwehr', 'Torwart'];
  const teamPlayersPosition = Object.keys(teamPlayers);
  const TeamPlayers = positions.map((position, index) => {
    const hasPlayersForPosition = teamPlayersPosition.includes(position);
    return hasPlayersForPosition ? getPlayers(position, index) : getPlaceholder(position);
  });

  const hasTeamPlayersWithoutPosition = teamPlayersPosition.includes('unbekannt') ? getPlayers('unbekannt') : null;
  TeamPlayers.push(hasTeamPlayersWithoutPosition);
  return <>{TeamPlayers}</>;
};

export default PlayerList;
