import {useSelector} from 'react-redux';
import {selectSsrRendered} from 'app/hooks/reduxCreateSelectorHooks';
import {useEffect, useState} from 'react';

const useHydration = () => {
  const ssrRendered = useSelector(selectSsrRendered);
  const [isHydrating, setIsHydrating] = useState(ssrRendered);

  useEffect(() => {
    setIsHydrating(false);
  }, []);

  return isHydrating;
};

export {useHydration};
