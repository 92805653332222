import React from 'react';
import styled from 'styled-components';
import {Subheading} from 'app/styles/heading';
import ProfileLinkRow from 'app/components/profile/ProfileLinkRow';
import {Placeholder} from '@fupa/fupa-uikit';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 100%;
`;

const ClubContactPersons = ({clubContacts}) => {
  const showClubContacts =
    clubContacts.length > 0 ? (
      clubContacts.map(contact => (
        <ProfileLinkRow
          isDeactivated={contact.isDeactivated}
          key={contact.slug}
          profileSlug={contact.slug}
          profileName={contact.name}
          profileImage={contact.image}
          additionalInformation={contact.job}
          type='contact'
        />
      ))
    ) : (
      <Placeholder>Es sind keine Ansprechpartner eingetragen.</Placeholder>
    );

  return (
    <MainContainer>
      <Subheading variant='subheading2'>Ansprechpartner</Subheading>
      {showClubContacts}
    </MainContainer>
  );
};

export default ClubContactPersons;
