import React, {useReducer} from 'react';
import PlayerList from 'app/components/player/playerList';
import TeamInfo from 'app/components/team/teamInfo';
import {useSelector} from 'react-redux';
import {ContentAdDesktop} from 'app/styles/content';
import CoachList from 'app/components/coach/CoachList';
import {Helmet} from 'react-helmet-async';
import {LoadingIndicator, LOADING_MESSAGE, Image} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {LinkOnComponent} from 'app/components/links/link';
import {selectAuthState} from 'app/hooks/reduxCreateSelectorHooks';
import {EditFabContainer} from 'app/components/floatingAction/EditFab';
import {AddTeamToHomeScreen} from 'app/components/team/AddTeamToHomeScreen';
import {fetchTeamPlayers} from 'app/routes/team/TeamPageActions';
import {useParams} from 'react-router';
import {TeamPlayersPageReducer} from 'app/routes/team/TeamPageReducer';
import {useFetchData, useRestoreData, useEffectAfterMount, useDataFetchFinished} from 'app/hooks/dataHooks';
import StickyMobileBannerAdSlot from 'app/components/ads/StickyMobileBannerAdSlot';
import {Chip} from '@mui/material';
import {Link} from '@mui/icons-material';
import ChipImage from 'app/components/chip/ChipImage';
import {LinkToCompetition} from 'app/components/links/LinkToCompetition';

const ShortcutItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0.25rem;
  padding: 0.25rem 0.75rem 0;
`;

const Wrapper = styled.div`
  ${props => props.theme.desktopLayout`
    padding: 0 3rem;
  `}
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 56.25vw;
  ${props => props.theme.desktopLayout`
      max-width: ${props => props.theme.desktopContentWidth}px;
      max-height: ${props => (props.theme.desktopContentWidth * 9) / 16}px;
    `}
`;

const Spacer = styled.div`
  height: 1.5rem;
`;

const StyledChipImage = styled(ChipImage)`
  img {
    padding: 2px;
  }
`;

const TeamPlayersPage = ({team, dispatchRedux}) => {
  const isAuthenticated = useSelector(selectAuthState);
  const {teamSlug} = useParams();
  const meta = [{name: 'robots', content: 'noindex, follow'}];

  const {restoredData} = useRestoreData('TeamPlayersPage');
  const initialState = restoredData ?? {
    data: {
      players: [],
      coaches: [],
      info: {},
    },
    isFetching: false,
  };
  const [state, dispatch] = useReducer(TeamPlayersPageReducer, initialState);
  const loadData = forceRefresh => fetchTeamPlayers(teamSlug, forceRefresh, dispatchRedux)(dispatch, state);
  const {
    data: {players, coaches, info},
    isFetching,
  } = state;
  const loadingDataFinished = useDataFetchFinished(isFetching);
  useFetchData(state, loadData, 'TeamPlayersPage');

  useEffectAfterMount(() => loadData(true), [teamSlug]);

  const nextTeamSeason = team?.nextTeamSeason?.slug ? (
    <Chip
      style={{display: 'flex'}}
      avatar={<Link />}
      label={`Zum Kader ${team.nextTeamSeason.season?.name ?? 'der Folgesaison'}`}
      variant='outlined'
      clickable
      component={LinkOnComponent}
      to={`/team/${team.nextTeamSeason.slug}`}
    />
  ) : (
    <Spacer />
  );

  const clubLinkImage = team?.club?.image ? (
    <StyledChipImage entity={'emblem'} src={team.club.image} ratio={'1:1'} fit={true} />
  ) : null;

  const leagueLinkImage = team?.competition?.image ? (
    <StyledChipImage entity={'competition'} src={team.competition.image} ratio={'1:1'} fit={true} />
  ) : null;

  const clubLink = team?.club?.slug ? (
    <Chip
      style={{display: 'flex'}}
      avatar={clubLinkImage}
      label={'Zum Verein'}
      variant='outlined'
      clickable
      component={LinkOnComponent}
      to={`/club/${team.club.slug}`}
    />
  ) : null;

  const leagueLink = team?.competition?.slug ? (
    <Chip
      style={{display: 'flex'}}
      avatar={leagueLinkImage}
      label={'Zur Tabelle'}
      variant='outlined'
      clickable
      component={LinkToCompetition}
      competition={team.competition}
      subpage='standing'
    />
  ) : null;

  const isPlaceholderTeamImage = info?.teamImage?.path?.includes('FcwELVo19Aez');
  const hasTeamImage = !isPlaceholderTeamImage && info?.teamImage;
  const image = hasTeamImage ? (
    <ImageContainer>
      <Image entity='team-image' src={info.teamImage} actualWidthFactor={1} ratio='16:9' />
    </ImageContainer>
  ) : null;
  const content = loadingDataFinished ? (
    <Wrapper>
      <ShortcutItems>
        {clubLink}
        {leagueLink}
        {nextTeamSeason}
        <AddTeamToHomeScreen team={team} />
      </ShortcutItems>
      <PlayerList hasTeamImage={hasTeamImage} squad={players} teamId={team.id} />
      <CoachList squad={coaches} teamId={team.id} />
      <TeamInfo team={info} />
    </Wrapper>
  ) : (
    <LoadingIndicator message={LOADING_MESSAGE.loadingPlayers} />
  );

  const editBtn =
    isAuthenticated && team?.id ? (
      <EditFabContainer
        hasMobileSticky
        url={`${process.env.ADMIN_URL}/fupa/admin/index.php?page=team&team=${team.id}`}
      />
    ) : null;

  return (
    <>
      <ContentAdDesktop noContentPadding contentPaddingBottom={'4.5rem'} top='7.125rem' fabBtn={<>{editBtn}</>}>
        <Helmet meta={meta} />
        {image}
        {content}
      </ContentAdDesktop>
      <StickyMobileBannerAdSlot />
    </>
  );
};

export default TeamPlayersPage;
