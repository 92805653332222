import React from 'react';
import {Subheading} from 'app/styles/heading';
import {AddTeamPlayerTeaser} from 'app/components/team/addTeamPlayerTeaser';
import ProfileCard from 'app/components/player/profileCard';
import {ProfileWrapper} from 'app/components/profile/ProfileStyles';

const coachRow = coach => {
  return <ProfileCard key={coach.slug} profile={coach} role='coach' />;
};

const CoachList = ({squad, teamId}) => {
  const TeamCoaches = squad.length ? (
    <>
      <Subheading variant='subheading2'>Trainer</Subheading>
      <ProfileWrapper>{squad.map(coach => coachRow(coach))}</ProfileWrapper>
    </>
  ) : (
    <AddTeamPlayerTeaser position='Trainer' teamId={teamId} />
  );

  return <>{TeamCoaches}</>;
};

export default CoachList;
