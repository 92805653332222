import React, {useEffect, useState} from 'react';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import {Chip} from '@mui/material';
import {useNative} from 'app/contexts/NativeContext';

const AddTeamToHomeScreen = ({team}) => {
  const [showShortcut, setShowShortcut] = useState(false);
  const origin = process.env.SSR_ORIGIN_URL ?? 'https://www.fupa.net';
  const linkUrl = team.teams?.[0]?.slug ? `${origin}/team/${team.teams[0].slug}` : null;
  const {isNativeApp, platform, isFlutter} = useNative();
  const isAndroid = platform === 'android';

  useEffect(() => {
    if (isAndroid && linkUrl) {
      if (isNativeApp && !isFlutter) {
        setShowShortcut(window.native.areShortcutsEnabled?.(linkUrl));
      } else if (isNativeApp && isFlutter) {
        if (window.native_are_shortcuts_enabled) {
          window.native_are_shortcuts_enabled_callback = enabled => {
            setShowShortcut(enabled);
          };
          window.native_are_shortcuts_enabled.postMessage(linkUrl);
        }
      }
    }
  }, [team]);

  const onClick = () => {
    if (isAndroid && linkUrl) {
      if (isNativeApp && !isFlutter) {
        window.native?.createShortcut?.(team.name?.middle, linkUrl, team.image?.path);
      } else if (isNativeApp && isFlutter) {
        window.native_create_shortcut?.postMessage(
          JSON.stringify({
            shortLabel: team.name?.middle,
            linkingUrl: linkUrl,
            imageUrl: team.image?.path,
          })
        );
      }
    }
  };
  if (showShortcut && !team?.nextTeamSeason?.slug) {
    return (
      <Chip
        style={{display: 'flex'}}
        avatar={<AddToHomeScreenIcon />}
        label={'Zum Home-Screen hinzufügen'}
        variant='outlined'
        clickable
        onClick={onClick}
      />
    );
  } else {
    return null;
  }
};

export {AddTeamToHomeScreen};
