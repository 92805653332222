import React from 'react';
import {ContentAdDesktop} from 'app/styles/content';
import ClubInfos from 'app/components/club/ClubInfos';
import {useSelector} from 'react-redux';
import ClubContact from 'app/components/club/ClubContact';
import ClubContactPersons from 'app/components/club/ClubContactPersons';
import {Helmet} from 'react-helmet-async';
import {object, string} from 'prop-types';
import {ClubVenues} from 'app/components/club/ClubVenues';
import {AdSlot} from 'app/components/ads/AdSlot';
import {selectAuthState} from 'app/hooks/reduxCreateSelectorHooks';
import {EditFabContainer} from 'app/components/floatingAction/EditFab';
import {ClubManagers} from 'app/components/club/ClubManagers';

const ClubContactsPage = ({club, defaultTitle, districtName}) => {
  const isAuthenticated = useSelector(selectAuthState);
  const meta = [
    {
      name: 'description',
      content: `Alle Ansprechpartner zum Verein ${defaultTitle} auf einen Blick - Kreis ${districtName}.`,
    },
    {
      name: 'robots',
      content: 'noindex, follow',
    },
  ];

  const contacts = club?.contacts ?? [];
  const managers = club?.managers ?? [];

  // Use `club?.slug` to detect success api response
  const showData = club?.slug ? (
    <React.Fragment>
      <ClubInfos clubData={club} />
      <AdSlot key='Content_1' adUnit='Content_1' />
      <ClubContact clubData={club} />
      <AdSlot key='Rectangle_1' adUnit='Rectangle_1' />
      <ClubVenues venues={club.venues} />
    </React.Fragment>
  ) : null;

  const showContacts = <ClubContactPersons clubContacts={contacts} />;
  const showManagers = <ClubManagers clubManagers={managers} />;

  const editBtn =
    isAuthenticated && club?.id ? (
      <EditFabContainer url={`${process.env.ADMIN_URL}/fupa/admin/index.php?page=verein&verein_id=${club.id}`} />
    ) : null;

  return (
    <ContentAdDesktop top='7.125rem' fabBtn={editBtn}>
      <Helmet title='Ansprechpartner - ' meta={meta} />
      {showData}
      {showContacts}
      {showManagers}
    </ContentAdDesktop>
  );
};

ClubContactsPage.propTypes = {
  club: object.isRequired,
  defaultTitle: string.isRequired,
  districtName: string.isRequired,
};

export default ClubContactsPage;
