import React from 'react';
import {useAdDimensions} from 'app/components/ads/useAdDimensions';
import {AdCore} from 'app/components/ads/AdCore';
import {useAdUnitCode} from 'app/components/ads/adHelpers';

// AdSlotContainer: Component to set configuration to define ad slot
// only triggered in CSR
const AdSlotContainer = ({adUnit, limitedDim, adLayout, className}) => {
  const dimensions = useAdDimensions(limitedDim);
  const sizes = dimensions[adUnit];
  // adUnitCode needs to be unique for each container per page
  const adUnitCode = useAdUnitCode(adUnit);

  return (
    <div className={className}>
      <AdCore id={`ad-${adUnit}`} adLayout={adLayout} adUnitCode={adUnitCode} sizes={sizes} />
    </div>
  );
};

export {AdSlotContainer};
