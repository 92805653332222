import React from 'react';
import styled from 'styled-components';
import {Subheading} from 'app/styles/heading';
import ProfileLinkRow from 'app/components/profile/ProfileLinkRow';
import {Placeholder} from '@fupa/fupa-uikit';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 100%;
`;

const ClubManagers = ({clubManagers}) => {
  const showClubManagers =
    clubManagers.length > 0 ? (
      clubManagers.map(manager => (
        <ProfileLinkRow
          isDeactivated={manager.isDeactivated}
          key={manager.slug}
          profileSlug={manager.slug}
          profileName={manager.name}
          profileImage={manager.image}
          additionalInformation={manager.role}
          type='user'
          noLinking={true}
        />
      ))
    ) : (
      <Placeholder>Es sind keine Vereinsverwalter eingetragen.</Placeholder>
    );

  return (
    <MainContainer>
      <Subheading variant='subheading2'>Vereinsverwalter</Subheading>
      {showClubManagers}
    </MainContainer>
  );
};

export {ClubManagers};
