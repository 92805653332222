import React from 'react';
import {Subheading} from 'app/styles/heading';
import {COLORS, Image, Placeholder, SIZES, Typography} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {mapsSelector} from 'app/helpers/helpers';

const Row = styled.div`
  cursor: pointer;
  display: flex;
  flex: 0 100%;
  padding: 0.5rem 1rem;
  margin-bottom: ${SIZES['8']};
  justify-content: center;
  align-items: center;
  align-content: center;
  &:hover {
    background-color: ${COLORS.lightestGrey};
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  padding-left: ${SIZES['16']};
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ClubVenues = ({venues}) => {
  const content =
    venues.length > 0 ? (
      venues.map(venue => {
        return (
          <React.Fragment key={venue.id}>
            <Row onClick={() => mapsSelector(venue)}>
              <ImageContainer>
                <Image entity='venue' ratio='1:1' width={64} height={64} circle={true} src={venue.image} />
              </ImageContainer>
              <InnerContainer>
                <Typography variant='body1'>{venue.name}</Typography>
                <Typography variant='body2'>{venue.street}</Typography>
                <Typography variant='body2'>
                  {venue.postcode} {venue.city}
                </Typography>
              </InnerContainer>
            </Row>
          </React.Fragment>
        );
      })
    ) : (
      <Placeholder>Es sind keine Spielstätten eingetragen.</Placeholder>
    );

  return (
    <React.Fragment>
      <Subheading variant='subheading2'>Spielstätten</Subheading>
      {content}
    </React.Fragment>
  );
};

export {ClubVenues};
