import {Subheading} from 'app/styles/heading';
import {Person} from '@mui/icons-material';
import {Button, COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import React from 'react';
import styled, {css} from 'styled-components';

const Wrapper = styled.div`
  ${props =>
    props.nopadding &&
    css`
      h3 {
        padding-top: 0;
      }
    `}
`;

const Row = styled.div`
  display: flex;
  padding: 0 1rem 1rem 1rem;
  align-items: center;
  white-space: pre-line;
`;

const ImagePlaceholder = styled.div`
  width: ${SIZES['64']};
  height: ${SIZES['64']};
  border-radius: ${SIZES['32']};
  background-color: ${COLORS.lightestGrey};
  margin-right: 0.75rem;
`;

const SCButton = styled(Button)`
  &&& {
    margin-top: 0.5rem;
  }
`;

const placeholderText = {
  Torwart: 'Kein Torwart eingetragen. \n Hier ist wohl jeder Schuss ein Gegentreffer? 🙉',
  Abwehr: 'Kein Spieler eingetragen. \n Die Abwehr ist ganz schön löchrig! 🧀',
  Mittelfeld: 'Kein Spieler eingetragen. \n Ziemlich wenig Bewegung im Kader! 😵‍💫',
  Angriff: 'Kein Spieler eingetragen. \n Euer Angriff ist fast unsichtbar! 👻',
  Trainer: 'Kein Trainer eingetragen. \n Wer hält hier die Truppe zusammen? 🤔',
};

const AddTeamPlayerTeaser = ({position, teamId}) => {
  const handleClick = () => {
    window.open(
      `${process.env.ADMIN_URL}/fupa/admin/index.php?page=team&team=${teamId}&utm_source=fupa&utm_medium=teaser&utm_campaign=squad_without_player`
    );
  };

  if (!position || position === 'unbekannt') {
    return null;
  }

  const btnLabel = position === 'Trainer' ? 'Trainer eintragen' : 'Spieler in Kader eintragen';
  const heading = <Subheading variant='subheading2'>{position}</Subheading>;
  return (
    <Wrapper nopadding={position === 'Torwart'} key={position}>
      {heading}
      <Row>
        <ImagePlaceholder>
          <Person style={{color: COLORS.lightGrey, fontSize: 64}} />
        </ImagePlaceholder>
        <div>
          <Typography variant='body1'>{placeholderText[position]} </Typography>
          <SCButton label={btnLabel} onClick={handleClick} />
        </div>
      </Row>
    </Wrapper>
  );
};

export {AddTeamPlayerTeaser};
