import React from 'react';
import {Subheading} from 'app/styles/heading';
import {TextWithLabel, TextWithLabelContainer} from 'app/components/textWithLabel/TextWithLabel';

const TeamInfo = ({team}) => {
  const facts = {
    club: {
      name: team.club ? team.club.name : '-',
      url: team.club ? `/club/${team.club.slug}` : '',
      image: team.club?.image ? team.club.image : null,
    },
    captain: team.captain ? `${team.captain?.firstName} ${team.captain?.lastName}` : '–',
    viceCaptain: team.viceCaptain ? `${team.viceCaptain?.firstName} ${team.viceCaptain?.lastName}` : '–',
    seasonTarget: (team.targetDescription || team.seasonTarget) ?? '–',
    championFavourit: team.championFavourit ? team.championFavourit : '–',
  };

  const contacts = team.contacts ? team.contacts : [];
  const ContactPersons = contacts.map(contact => (
    <TextWithLabel key={contact.slug} label={contact.job || 'Kontakt'} data={contact.name} />
  ));

  return (
    <div>
      <Subheading variant='subheading2'>Teaminfo</Subheading>
      <TextWithLabelContainer>
        <TextWithLabel
          label='Verein'
          data={facts.club.name}
          link={facts.club.url}
          decoration={facts.club.name !== '-'}
          img={{src: facts.club.image, type: 'emblem'}}
        />
        {ContactPersons}
        <TextWithLabel label='Kapitän' data={facts.captain} />
        <TextWithLabel label='Vize-Kapitän' data={facts.viceCaptain} />
        <TextWithLabel label='Saisonziel' data={facts.seasonTarget} />
        <TextWithLabel label='Meisterfavorit' data={facts.championFavourit} />
      </TextWithLabelContainer>
    </div>
  );
};

export default TeamInfo;
