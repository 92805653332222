import {LinkOnComponent} from 'app/components/links/link';
import styled from 'styled-components';
import {Assist, Card, Football, FootballShoe, New} from 'app/components/icons';
import {COLORS} from '@fupa/fupa-uikit';
import React from 'react';
import {LocalHospital} from '@mui/icons-material';
import {Image} from '@fupa/fupa-uikit';

const ProfileWrapper = styled.div`
  ${props => (props.highlightColor ? 'border: 2px solid ' + props.highlightColor : 'border: 2px solid transparent')};
  box-sizing: border-box;
  position: relative;
  width: 150px;
  height: 188px;
  border-radius: 8px;
  padding: 0.5rem;
  overflow: hidden;
`;

const ProfileImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
const ProfileShadowBox = styled.div`
  width: 100%;
  height: 94px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), ${COLORS.black});
`;
const ProfileContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  white-space: nowrap;
`;
const Badge = styled.div`
  position: absolute;
  top: 0;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid ${COLORS.grey};
  font-size: 14px;
  font-weight: 400;
  font-family: 'Oswald', sans-serif;
  color: ${COLORS.black};
  background-color: ${COLORS.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const JerseyNumber = styled(Badge)`
  right: 0;
`;

const CaptainMark = styled(Badge)`
  right: 0;
  ${props => (props.hasJerseyNumber ? 'top: 1.75rem;' : null)};
`;

const StatusFlag = styled.div`
  position: absolute;
  top: 0;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  left: 0;
  & > svg {
    z-index: 1;
    color: ${COLORS.white};
    font-size: 1rem;
  }
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 40px 0 0;
    border-color: ${props => props.color} transparent transparent transparent;
    left: 0;
    top: 0;
    position: absolute;
  }
`;

const ProfileName = styled.div`
  width: inherit;
  font-size: ${props => (props.size === 'small' ? 14 : 20)}px;
  font-weight: 400;
  font-family: 'Oswald', sans-serif;
  color: ${COLORS.white};
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => (props.size === 'small' ? 'margin-bottom: -2px;' : null)};
`;
const PlayerStats = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
  padding-top: 0.5rem;
  height: 1.5rem;
`;

const ProfileAdditionalInfo = styled.div`
  font-size: 10px;
  font-weight: 400;
  font-family: 'Oswald', sans-serif;
  color: ${COLORS.white};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PlayerStatIcon = styled.div`
  display: inline-flex;
  margin-right: 8px;
`;

const getPlayerStatistics = statistics => {
  const stats = Object.entries(statistics).map(entry => {
    const key = entry[0];
    const value = entry[1];
    if (value <= 0) {
      return null;
    }
    const iconMap = {
      matches: <FootballShoe style={{color: COLORS.white, fontSize: 16}} />,
      goals: <Football style={{color: COLORS.white, fontSize: 16}} />,
      assists: <Assist style={{color: COLORS.white, fontSize: 16}} />,
    };
    return (
      <ProfileAdditionalInfo>
        <PlayerStatIcon>{iconMap[key]}</PlayerStatIcon>
        <div>{value}</div>
      </ProfileAdditionalInfo>
    );
  });
  return stats;
};

const StatusFlagsStyles = {
  new: {
    color: COLORS.fupaSecondary,
    icon: <New style={{color: COLORS.white, fontSize: 16}} />,
  },
  banned: {
    color: COLORS.red,
    icon: <Card />,
  },
  injured: {
    color: COLORS.neonRed,
    icon: <LocalHospital />,
  },
};

const priorizedStatusFlags = ['injured', 'banned', 'new'];

const ProfileCard = ({profile, role}) => {
  let additionalInfo,
    captainMark,
    jerseyNumber,
    highlightColor,
    statusFlag = null;

  if (role === 'player') {
    const {matches, goals} = profile;
    const statistics = {matches, goals};
    jerseyNumber = profile.jerseyNumber ? <JerseyNumber>{profile.jerseyNumber}</JerseyNumber> : null;
    captainMark = profile.flags.includes('captain') ? (
      <CaptainMark hasJerseyNumber={Boolean(profile.jerseyNumber)}>C</CaptainMark>
    ) : null;
    additionalInfo = <PlayerStats>{getPlayerStatistics(statistics)}</PlayerStats>;

    const matchedStatusFlag = priorizedStatusFlags.find(flag => {
      if (profile.flags?.includes(flag)) {
        return flag;
      }
    });

    if (matchedStatusFlag) {
      const {color, icon} = StatusFlagsStyles[matchedStatusFlag];
      highlightColor = color;
      statusFlag = <StatusFlag color={color}>{icon}</StatusFlag>;
    }
  } else if (role === 'coach') {
    additionalInfo = (
      <ProfileAdditionalInfo>
        <div>{profile.role}</div>
      </ProfileAdditionalInfo>
    );
  }

  const content = (
    <ProfileWrapper highlightColor={highlightColor}>
      <ProfileImage>
        <Image
          alt={`${profile?.firstName} ${profile.lastName}`}
          entity={'player'}
          ratio={'4:5'}
          src={profile.image}
          height={188}
          width={150}
        />
      </ProfileImage>
      <ProfileShadowBox />
      {statusFlag}
      <ProfileContainer>
        {captainMark}
        {jerseyNumber}
        {profile?.firstName && <ProfileName size={'small'}>{profile.firstName}</ProfileName>}
        <ProfileName>{profile.lastName}</ProfileName>
        {additionalInfo}
      </ProfileContainer>
    </ProfileWrapper>
  );

  return profile.slug ? <LinkOnComponent to={`/player/${profile.slug}`}>{content}</LinkOnComponent> : content;
};

export default ProfileCard;
