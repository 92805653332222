import React from 'react';
import {Subheading} from 'app/styles/heading';
import {TextWithLabel, TextWithLabelContainer} from 'app/components/textWithLabel/TextWithLabel';

const ClubInfos = ({clubData}) => {
  const facts = {
    founded: clubData.founded ? clubData.founded : '–',
    colors: clubData.colors ? clubData.colors : '-',
    memberCount: clubData.memberCount ? clubData.memberCount : '-',
    departments: clubData.departments ? clubData.departments : '-',
    achievements: clubData.achievements ? clubData.achievements : '-',
  };

  return (
    <React.Fragment>
      <Subheading variant='subheading2'>Infos</Subheading>
      <TextWithLabelContainer>
        <TextWithLabel label='Gründungsjahr' data={facts.founded} />
        <TextWithLabel label='Vereinsfarben' data={facts.colors} />
        <TextWithLabel label='Mitgliederzahl' data={facts.memberCount} />
        <TextWithLabel label='Abteilung/Sparten' data={facts.departments} />
        <TextWithLabel label='Sportliche Erfolge' data={facts.achievements} noPaddingBottom={true} />
      </TextWithLabelContainer>
    </React.Fragment>
  );
};

export default ClubInfos;
