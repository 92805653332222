import React from 'react';
import {Subheading} from 'app/styles/heading';
import styled from 'styled-components';
import {COLORS} from '@fupa/fupa-uikit';
import {FlexContainer} from 'app/styles/content';
import {
  TextWithLabel,
  TextWithLabelContainer,
  IconAndTextWithLabelContainer,
} from 'app/components/textWithLabel/TextWithLabel';
import {LocationOn, Mail, Phone, Language} from '@mui/icons-material';
import {FPHTMLLink} from 'app/components/links/link';

const ExternalLink = styled(FPHTMLLink)`
  text-decoration: none;
  cursor: pointer;
`;

const ClubContact = ({clubData}) => {
  const facts = {
    address: clubData.address ? clubData.address : '–',
    email: clubData.email ? clubData.email : '-',
    website: clubData.website ? clubData.website : '-',
    phoneClub: clubData.phoneClub ? clubData.phoneClub : '-',
    phoneClubHome: clubData.phoneClubHome ? clubData.phoneClubHome : '-',
    phoneVenue: clubData.phoneVenue ? clubData.phoneVenue : '-',
  };

  const clubWeb = facts.website.startsWith('http') ? facts.website : 'http://' + facts.website;
  const websiteInfo =
    facts.website !== '-' ? (
      <ExternalLink href={clubWeb} target='_blank'>
        <TextWithLabel label='Webseite' data={facts.website} decoration={facts.website !== '-'} />
      </ExternalLink>
    ) : (
      <TextWithLabel label='Webseite' data={facts.website} />
    );

  return (
    <React.Fragment>
      <Subheading variant='subheading2'>Kontakt</Subheading>
      <TextWithLabelContainer>
        <FlexContainer>
          <IconAndTextWithLabelContainer>
            <LocationOn style={{color: COLORS.darkGrey}} />
          </IconAndTextWithLabelContainer>
          <TextWithLabel label='Vereinsanschrift' data={facts.address} />
        </FlexContainer>
        <FlexContainer>
          <IconAndTextWithLabelContainer>
            <Mail style={{color: COLORS.darkGrey}} />
          </IconAndTextWithLabelContainer>
          <TextWithLabel label='E-Mail' data={facts.email} />
        </FlexContainer>
        <FlexContainer>
          <IconAndTextWithLabelContainer>
            <Language style={{color: COLORS.darkGrey}} />
          </IconAndTextWithLabelContainer>
          {websiteInfo}
        </FlexContainer>
        <FlexContainer>
          <IconAndTextWithLabelContainer>
            <Phone style={{color: COLORS.darkGrey}} />
          </IconAndTextWithLabelContainer>
          <TextWithLabel label='Verein' data={facts.phoneClub} />
        </FlexContainer>
        <FlexContainer>
          <IconAndTextWithLabelContainer />
          <TextWithLabel label='Vereinsheim' data={facts.phoneClubHome} />
        </FlexContainer>
        <FlexContainer>
          <IconAndTextWithLabelContainer />
          <TextWithLabel noPaddingBottom={true} label='Sportanlage' data={facts.phoneVenue} />
        </FlexContainer>
      </TextWithLabelContainer>
    </React.Fragment>
  );
};

export default ClubContact;
